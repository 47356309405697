@font-face {
	font-family: 'ProductSans';
	src: local('ProductSans'), url(../../src/fonts/ProductSans/ProductSans-Regular.woff) format('woff');
}

@font-face {
	font-family: 'ProductSans';
	font-weight: 900;
	src: local('ProductSans'), url(../../src/fonts/ProductSans/ProductSans-Bold.woff) format('woff');
}

@font-face {
	font-family: 'ProductSans';
	font-weight: 900;
	src: local('ProductSans'), url(../../src/fonts/ProductSans/ProductSans-Black.woff) format('woff');
}

.App {
	text-align: center;
}

.recharts-sector{
	outline: none;
}